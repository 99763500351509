import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyCZKRU9FzCoNSsqO2qwwDVFXVD9mWYQh7s",
    authDomain: "gokind-4f5cd.firebaseapp.com",
    databaseURL: "https://gokind-4f5cd.firebaseio.com",
    projectId: "gokind-4f5cd",
    storageBucket: "gokind-4f5cd.appspot.com",
    messagingSenderId: "794964390607",
    appId: "1:794964390607:web:283284380b25a2e150fdba",
    measurementId: "G-M4S7RHFJ74"
}

firebase.initializeApp(firebaseConfig)

// firebase.functions().useFunctionsEmulator('http://localhost:5001');

const GET = (ref) => {
    return new Promise((resolve, reject) => {
        let fetchMethod = 'server'
        //console.log(fetchMethod)
        ref.get({
            source: fetchMethod // Change source to 'server' to remove caching
        }).then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

const UPDATE = (ref, obj) => {
    return new Promise((resolve, reject) => {
        console.log(ref)
        ref.update(obj).then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

const ADD = (ref, obj) => {
    return new Promise((resolve, reject) => {
        console.log(ref)
        ref.add(obj).then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

const SET = (ref, obj) => {
    return new Promise((resolve, reject) => {
        console.log(ref)
        ref.set(obj).then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

const DELETE = (ref) => {
    return new Promise((resolve, reject) => {
        console.log(ref)
        ref.delete().then(data => {
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

// utils
let db;
db = firebase.firestore()

export {
    db,
    GET,
    ADD,
    UPDATE,
    SET,
    DELETE,
    firebase,
}