import {
    ADD,
    db,
    UPDATE,
    firebase,
} from '@/firebase.js'


export default {
    name: 'Finput',
    props: {
        minimized: Boolean,
    },
    data: function () {
        return {
            websiteId: null,
            sentWebsiteEmail: false,
            inputVal: null,
            emailToWebsite: null,
            showWebsiteModal: false,
            showNoReportModal: false,
            activeStep: 0,
            websiteSteps: null,
            emailError: null,
            savedSteps: [{
                type: 'assets',
                title: 'Establishing connection',
                desc: 'Fetching brand assets'
            }, {
                title: 'Auto-generating the hub',
                desc: 'Takes about 4-5 days',
            }, {
                title: 'Make changes and additions',
            }, {
                title: 'Publish your hub',
            }]
        }
    },
    methods: {
        hideFinputModal: function () {
            const query = {
                ...this.$route.query,
                modal: null
            };
            this.$router.replace({
                query
            })
        },
        closeEmailModal: function () {
            this.showEmailModal = false
        },
        closeWebsiteModal: function () {
            this.showWebsiteModal = false
            this.activeStep = 0
            this.websiteSteps = this.savedSteps
        },
        assetsFetched: function (success = true) {
            if (success) {
                this.websiteSteps[0].type = 'assetsLoaded'
            } else {
                this.websiteSteps[0].type = 'assetsNoLoading'
            }
            setTimeout(() => {
                this.activeStep = 1
            }, 3000)
        },
        addEmailToWebsite: function () {
            if (!this.emailToWebsite) return
            UPDATE(db.doc('requests/' + this.websiteId), {
                email: this.emailToWebsite
            }).then(() => this.sentWebsiteEmail = true)
        },
        submitForm: function () {
            if (!this.inputVal) return
            if (this.$route.query.t) this.addWebsite()
            else this.addEmail()
        },
        addWebsite: function () {
            ADD(db.collection('requests'), {
                date: new Date(),
                website: this.inputVal
            }).then(doc => this.websiteId = doc.id)
            this.showWebsiteModal = true
        },
        addEmail: async function (auth = 'email') {
            this.$router.replace({
                query: {
                    loading: true
                }
            })
            let userObj = {
                verified: (auth === 'email' ? false : true),
                date: new Date(),
                email: this.inputVal,
                auth: auth,
                uid: null
            }
            if (auth === 'Google') {
                let user = firebase.auth().currentUser;
                userObj.uid = user.uid
            }
            let response = await this.getIdFromEmail()
            console.log(response)
            if (response.exists) {
                this.$router.push({
                    path: '/tailor?email=' + this.inputVal + '&confId=' + response.id
                })
            } else {
                ADD(db.collection('users'), userObj).then((doc) => {
                    this.$router.push({
                        path: '/tailor?email=' + this.inputVal + '&confId=' + doc.id
                    })
                })
            }
        },
        getIdFromEmail: function () {
            return new Promise(resolve => {
                let getIdFromEmail = firebase.functions().httpsCallable('getIdFromEmail');
                getIdFromEmail({
                    email: this.inputVal
                }).then(result => {
                    console.log(result)
                    resolve(result.data)
                });
            })
        },
        getPlaceholder: function () {
            if (window.innerWidth < 600) return 'Email for updates'
            let placeholder = 'name@'
            if (!this.inputVal) return placeholder + 'acme.com'
            let mail = this.inputVal.split('.')
            if (mail[mail.length - 2]) placeholder += mail[mail.length - 2] + '.'
            if (mail[mail.length - 1]) placeholder += mail[mail.length - 1]
            return placeholder
        },
        getFinputPlaceholder: function () {
            if (this.$route.query.t) {
                return window.innerWidth < 600 ? `Company's website` : `Your company's website`
            } else return 'Your email'
        },
        googleSignIn: function () {
            this.$router.replace({
                query: {
                    loading: true
                }
            })
            let provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/userinfo.email');
            provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
            firebase.auth().signInWithPopup(provider).then(result => {
                // The signed-in user info.
                console.log(result)
                let email = result.user.email;
                if (!email) email = result.additionalUserInfo.profile.email
                this.inputVal = email
                this.addEmail('Google')
            }).catch(function (error) {
                console.log('ERROR:' + error.message)
            });
        }
    },
    created: function () {
        this.websiteSteps = Object.assign({}, this.savedSteps)
    }
}