var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.$route.query.loading)?_c('Loading'):_vm._e(),(
      !_vm.scaledDownPaths.includes(_vm.$route.name) &&
      !(_vm.mobile() && (_vm.$route.name === 'Confirm' || _vm.$route.name === 'Tailor'))
    )?_c('Menu'):_vm._e(),_c('router-view',{attrs:{"faqs":_vm.faqs}}),(
      !_vm.scaledDownPaths.includes(_vm.$route.name) &&
      _vm.$route.name !== 'Confirm' &&
      _vm.$route.name !== 'Tailor'
    )?_c('Footer',{attrs:{"showOnlyFooter":_vm.$route.name === 'Students'}}):_vm._e(),_c('Cookies')],1)}
var staticRenderFns = []

export { render, staticRenderFns }