import Menu from './components/Menu/Menu.vue';
import Footer from '@/components/Footer/Footer.vue'
import Cookies from '@/components/Cookies/Cookies.vue'
import Loading from '@/components/Loading/Loading.vue'

import SmoothScroll from 'smooth-scroll'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/goodkit/theme.min.css';
import './assets/goodkit/theme-sans-serif.min.css';

export default {
    name: 'App',
    metaInfo() {
        return {
            title: "Gokind - The Bridge Between Corporate Fluff and a Generation with an 8 Second Attention Span.",
            icon: require('@/assets/img/shared/favicon-light.png'),
            meta: [{
                    vmid: 'keywords',
                    name: 'keywords',
                    content: 'Gokind, sustainability, CSR, equality'
                }, {
                    vmid: 'description',
                    name: 'description',
                    content: 'Gokind is a platform where you discover your next employer, brand or investment, all based on your standards on sustainability and equality.'
                }, {
                    vmid: 'og:image',
                    name: 'og:image',
                    content: require('@/assets/img/shared/og-image.png')
                }, {
                    vmid: 'og:logo',
                    name: 'og:logo',
                    content: require('@/assets/img/shared/favicon-light.png')
                }, {
                    vmid: 'og:description',
                    name: 'og:description',
                    content: 'Gokind is a platform where you discover your next employer, brand or investment, all based on your standards on sustainability and equality.'
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: "Gokind - Discover companies that aren't shady"
                },
                {
                    property: 'og:site_name',
                    content: 'Gokind'
                },
                {
                    property: 'og:type',
                    content: 'website'
                }
            ]
        }
    },
    components: {
        Menu,
        Footer,
        Cookies,
        Loading
    },
    data: function () {
        return {
            scaledDownPaths: ['Live Demo', 'Access', 'Success', 'Policy'],
            faqs: {
                person: [{
                    emoji: '👿',
                    question: 'How do you know the companies aren’t shady?',
                    answer: "We make sure that the data and statistics presented on our platform are relevant for our users and are based on information that the companies themselves have published or provided. We will never wilfully publish any incorrect or misleading information. If information communicated by a certain company is false, or alleged to be false or misleading, we will of course take appropriate actions. Any inquiries should be directed to qa@gokind.co"
                }, {
                    emoji: '💸',
                    question: 'What does it cost?',
                    answer: 'Its 100% free for users.'
                }, {
                    emoji: '🌍',
                    question: 'How does this improve the world?',
                    answer: 'We empower people to make informed, sustainable decisions, by making it easy to distinguish corporate fluff from the real stuff. It’s also a matter of accelerating companies’ sustainability and equality work by nudging them and applying behavioral effects such as the Mere-measurement effect and Principle of Commitment. We also use behavioral science to get the public more involved, with proven concepts like Gamification and Progressive Disclosure.'
                }, {
                    emoji: '🙏',
                    question: 'Do I get anything for making it this far down the page?',
                    answer: "Of course, use the referral code SIRREADALOT when signing up and we'll donate a school kit through UNICEF."
                }],
                company: [{
                    emoji: '🍿',
                    question: 'How can you guarantee engagement?',
                    answer: 'To ignite the spark we invest 100% of your first three monthly fees into marketing your Impact Hub. Then your reach and engagement will grow organically.  Bear in mind, 84% share content because it is a way to support causes they care about.'
                }, {
                    emoji: '💸',
                    question: 'What’s the cost of an Impact Hub?',
                    answer: "As a partner to Gokind you pay a monthly subscription fee, which includes access to your very own Impact Hub. The subscription can be cancelled at any time. Price is set to allow companies of all sizes to enroll. Contact us to get a quote."
                }, {
                    emoji: '⏱',
                    question: 'How much time do I have to invest?',
                    answer: 'It’s totally up to you. Our AI can auto-generate the Impact Hub, no time investment needed. If you want to get more invested you can make adjustments, additions and edits in the self-service admin tool that comes with the Impact Hub. You will also be able to have meetings with our Student Council to get insights and feedback on your Impact Hub, at no extra cost.'
                }, {
                    emoji: '🌍',
                    question: 'How does this improve the world?',
                    answer: 'We empower people to make informed, sustainable decisions, by making it easy to distinguish corporate fluff from the real stuff. It’s also a matter of accelerating companies’ sustainability and equality work by nudging them and applying behavioral effects such as the Mere-measurement effect and Principle of Commitment. We also use behavioral science to get the public more involved, with proven concepts like Gamification and Progressive Disclosure.'
                }]
            }
        }
    },
    methods: {
        mobile: function () {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        },
    },
    mounted: function () {
        AOS.init();
        new SmoothScroll('a[href*="#"]', {
            speed: 800,
            speedAsDuration: true
        });
    }
}