import Finput from '@/components/Finput/Finput.vue'

export default {
    name: 'Menu',
    components: {
        Finput
    },
    data: function () {
        return {
            mobileNavs: [{
                    title: 'Home',
                    icon: 'home-smile-2',
                    path: {
                        dir: '/',
                        name: 'Home'
                    }
                },
                {
                    title: 'Mission',
                    icon: 'service',
                    path: {
                        dir: '/mission',
                        name: 'Mission'
                    }
                },
                {
                    title: 'FAQ',
                    icon: 'chat-smile-2',
                    path: {
                        dir: '/faq',
                        name: 'FAQ'
                    }
                },
            ],
            navActive: false,
            showBotBtns: false,
            showOverlay: true,
        }
    },
    methods: {
        openConvModal: function () {
            const query = {
                ...this.$route.query,
                modal: true
            };
            this.$router.push({
                query
            })
        },
        scrollListenerBackgroundSwitcher: function () {
            window.onscroll = () => {
                if (window.scrollY > 100) {
                    this.navActive = true
                } else {
                    this.navActive = false
                }
                if (this.$route.name === 'Students') this.studentBoxListener()
            };
        },
        studentBoxListener: function () {
            if (window.scrollY >= 500 && (this.bodyHeight() - window.scrollY) > ((window.innerWidth < 600 ? 2000 : 1800) + window.innerHeight / 2.4) && !this.showBotBtns) {
                this.showBotBtns = true
            } else if (this.showBotBtns && (window.scrollY < 500 || ((window.innerWidth < 600 ? 2000 : 1800) + window.innerHeight / 2.4) > (this.bodyHeight() - window.scrollY))) {
                this.showBotBtns = false
            }
            if (window.scrollY > document.getElementById("students-requirements-container").offsetTop) {
                this.showOverlay = false
            } else {
                this.showOverlay = true
            }
        },
        bodyHeight: function () {
            let body = document.body,
                html = document.documentElement;

            let height = Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight);
            return height
        },
    },
    mounted: function () {
        this.scrollListenerBackgroundSwitcher()
    }
}