import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import VueAnalytics from 'vue-analytics'
import router from '@/router'



Vue.$cookies.config('60min')

export default {
    name: 'Cookies',

    data: function () {
        return {
            active: false
        }
    },
    methods: {
        acceptCookie: function () {
            Vue.$cookies.set('cookie', 'true')
            this.active = false

        },
        activateAnalytics: function () {
            Vue.use(VueAnalytics, {
                id: 'UA-175676214-2',
                router
            })
        }
    },
    mounted: function () {
        this.active = (Vue.$cookies.get('cookie') !== 'true')
        if (this.active) this.activateAnalytics()
    },
}