import Finput from '@/components/Finput/Finput.vue'


export default {
    name: "Footer",
    props: {
        showOnlyFooter: Boolean,
    },
    components: {
        Finput
    },
    methods: {
        getFooterIllu: function () {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return 'footer-illu-mobile'
            } else if (window.innerWidth > 1800) {
                return 'footer-illu-giant'
            } else {
                return 'footer-illu3'
            }
        }
    }
}